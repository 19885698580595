<template>
  <div class="home">
    <!-- eslint-disable -->
    <div class="legal-holder">
      <h1>Privacy Policy</h1>
      <p>Tuumik Systems OÜ (<strong>"Supplier"</strong>), a company registered in Estonia (registry code 14387706, address Värsi tn 14a, 10919 Tallinn, Estonia), develops a software application for team management that is made commercially available to customers (<strong>"Application"</strong>). This software is described in more detail at www.tuumik.com. This policy describes how the Supplier processes personal data in connection with the Application and any Service the Supplier may provide related to it.</p>
      <p>Any capitalized term used but not defined herein shall have the meaning stipulated for it in the Application's End User License Agreement and Terms of Service.</p>
      <p>Questions about this policy can be sent to support@tuumik.com.</p>
      <h2>1. Collection of Personal Data</h2>
      <p>1.1 Creating a subscription to the Application and Service requires the Customer to provide its the name and email address.</p>
      <p>1.2 To provide user support for Customers and communicate notices regarding the status of and potential updates to the Application or Service, the Supplier may request contact data from Customers that can include personal data (name, email address, telephone number of a technical contact person).</p>
      <p>1.3 The Supplier may also process personal data as a result of Customers using the Service to process data that includes personal data.</p>
      <h2>2. Purpose and Legal Basis for Processing</h2>
      <p>The Supplier processes personal data for the purpose of making the Application and Service available for Customers to use, providing the Service to Customers or to execute activities necessary to prepare for such provision. Such processing is based on the Supplier's legitimate interest to sell subscriptions to the Application and provide the Service related to it. It is also necessary for the performance of the Supplier's contractual obligations arising from the Applications's End User License Agreement and Terms of Service.</p>
      <h2>3. Use of Cloud Infrastructure</h2>
      <p>The Supplier uses cloud infrastructure to manage subscriptions to the Application and Service and provide the Service.</p>
      <h2>4. Retention</h2>
      <p>The Supplier processes personal data only for as long as it is necessary for the purpose stipulated in Section 2 above.</p>
      <h2>5. Data Subject Rights</h2>
      <p>Data subjects have the right to:</p>
      <p>5.1 request access to and rectification or erasure of personal data or restriction of processing concerning the data subject;</p>
      <p>5.2 object to processing of personal data;</p>
      <p>5.3 data portability;</p>
      <p>5.4 withdraw data processing consent at any time;</p>
      <p>5.5 file a complaint with the Estonian Data Protection Inspectorate (Tatari 39, 10134 Tallinn, Estonia, info@aki.ee).</p>
      <p class="update-date">Last update: December 8, 2024</p>
    </div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  mounted() {
    document.body.scrollIntoView({ behavior: 'instant' });
  },
};
</script>

<style scoped>
.home {
  padding: 3em 0 1em 0;
}

.legal-holder {
  margin: 6em 0;
  padding: 1em;
  text-align: justify;
  background-color: #ffffff;
  color: #000000;
  border-radius: 0.3em;
  border: 1px solid #cecece;
  box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.07);
}

.legal-holder h1 {
  font-size: 2.1em;
  text-align: center;
  margin: 1em 0 2em 0;
}

.legal-holder h2 {
  font-weight: 600;
  font-size: 1.2em;
  margin: 1em 0;
}

.update-date {
  color: #7f7f7f;
  margin: 3em 0 0 0;
}

@media only screen and (min-width: 768px) {
  .legal-holder {
    margin: 6em auto;
    padding: 2em 3em;
    max-width: 50em;
  }
}
</style>
